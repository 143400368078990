import React from 'react'

const FlowTradersLogo = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width={282.1}
            height={14.11}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 282.1 14.11'
            {...props}>
            <g>
                <path
                    d='M3.3,3v3.1h5.4v2.6H3.3v5.3H0V.3H10.1V3H3.3ZM31.5,11.3v2.7h-9V.3h3.3V11.3h5.7Zm13.4,.7c-1-1.4-1.5-3.2-1.5-4.9,0-3.6,1.9-7,6.1-7,1.9,0,3.4,.6,4.5,2.1,1,1.3,1.5,3.3,1.5,4.9s-.4,3.5-1.3,4.7c-1.1,1.6-2.8,2.3-4.7,2.3-1.9,.1-3.5-.6-4.6-2.1m2.4-7.7c-.3,.8-.4,2-.4,2.8,0,1.7,.3,4.4,2.6,4.4,1.1,0,1.7-.5,2.1-1.6,.3-.7,.4-1.9,.4-2.8,0-1-.1-2.2-.5-3.1s-1-1.3-2-1.3c-1.2,0-1.8,.6-2.2,1.6m37,9.7h-3.3l-2.3-9h0l-2.2,9h-3.2L69.6,.3h3.4l2.2,9.2h0L77.5,.3h3l2.4,9.2h0l.1-.6L85.1,.3h2.7l-3.5,13.7Zm57.5,0h-1.8V1.8h-4V.3h9.8V1.8h-4V14Zm24.4,0l-2.6-5.9h-3.9v5.9h-1.8V.3h5.3c1.2,0,2.3,.1,3.2,.8,.9,.7,1.5,1.8,1.5,3,0,1.6-1,3.1-2.6,3.5l2.8,6.3h-1.9s0,.1,0,.1Zm-3.2-7.3c.9,0,1.7-.1,2.4-.8,.5-.5,.7-1.1,.7-1.8,0-.8-.3-1.5-1-1.9-.6-.4-1.2-.4-2-.4h-3.5V6.7h3.4Zm28.1,7.3h-1.8l-1.4-4h-5.2l-1.3,4h-1.5L184.5,.3h2l4.6,13.7h0Zm-5.8-11.9l-2.2,6.6h4.3s-2.1-6.6-2.1-6.6Zm19-1.8h4.5c2,0,3.5,.4,4.9,1.9,1.2,1.4,1.7,3.1,1.7,4.9,0,1.7-.5,3.3-1.5,4.7-1.1,1.5-2.6,2.2-4.5,2.2h-5V.3h-.1Zm4.4,12.2c1.6,0,2.9-.4,3.9-1.7,.7-1,1-2.4,1-3.7,0-1.4-.4-2.9-1.3-4-1-1.2-2.1-1.4-3.6-1.4h-2.6V12.5h2.6Zm29.3,1.5h-9.1V.3h8.9V1.8h-7.2V6.1h5.7v1.4h-5.7v5h7.4v1.5Zm21.1,0l-2.6-5.9h-3.9v5.9h-1.8V.3h5.3c1.2,0,2.3,.1,3.2,.8,.9,.7,1.5,1.8,1.5,3,0,1.6-1,3.1-2.6,3.5l2.8,6.3h-1.9s0,.1,0,.1Zm-3.2-7.3c.9,0,1.7-.1,2.4-.8,.5-.5,.7-1.1,.7-1.8,0-.8-.3-1.5-1-1.9-.6-.4-1.2-.4-2-.4h-3.5V6.7h3.4Zm16.7,3.2c.6,1.9,2.2,2.8,4.2,2.8,.8,0,1.9-.2,2.5-.7,.5-.4,.9-1.1,.9-1.8,0-1.4-1.2-1.9-2.4-2.2l-2.3-.6c-.9-.2-1.8-.4-2.6-1.1-.7-.6-1.1-1.6-1.1-2.5,0-2.6,2.3-3.8,4.8-3.8s4.3,1.1,5,3.3l-1.6,.4c-.3-1.5-2-2.4-3.5-2.4-1.3,0-3,.7-3,2.2s1.2,1.9,2.6,2.2l2.1,.5c.9,.2,1.9,.5,2.6,1.1,.9,.7,1.3,1.6,1.3,2.7,0,1.2-.6,2.4-1.6,3.1-1,.8-2.4,1-3.7,1-2.9,0-4.8-1.2-5.7-3.9l1.5-.3h0Z'
                    fill='currentcolor'
                />
                <path fill='currentcolor' d='M119,14.1h-14V.1h14V14.1Z' fillRule='evenodd' />
            </g>
        </svg>
    )
}

export default FlowTradersLogo
