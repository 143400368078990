import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import * as myStyles from './styles.module.scss'
import Header from '../../basics/header'
import NavigationComponent from '../navigation'
import MobileNavigation from '../mobile-navigation'
import ClickableArea from '../../basics/clickable-area/component'
import { HeaderProps } from './types'
import { gsap } from 'gsap'
import Banner from '../banner'

const HeaderComponent = ({
    logo,
    nav,
    primaryCtaText,
    primaryCtaLink,
    bgColor = 'dark',
    ...props
}: HeaderProps) => {
    const styles: any = myStyles
    const headerHeight = 80
    const bannerHeight = 40
    const headerRef = useRef(null)
    let lastScrollTop = useRef(0)

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false)

    const handleScroll = () => {
        if (isMobileOpen) return

        const scrollTop = window.scrollY || document.documentElement.scrollTop
        const viewportHeight = window.innerHeight

        if (bgColor === 'transparent' && scrollTop > viewportHeight) {
            gsap.to(headerRef.current, {
                backgroundColor: 'black',
                duration: 0.3,
                ease: 'power2.out',
            })
        } else {
            gsap.to(headerRef.current, {
                backgroundColor: 'transparent',
                duration: 0.3,
                ease: 'power2.out',
            })
        }
        if (scrollTop > headerHeight + bannerHeight) {
            if (scrollTop > lastScrollTop.current) {
                gsap.to(headerRef.current, { y: '-100%', duration: 0.3, ease: 'power2.out' })
            } else {
                gsap.to(headerRef.current, { y: '0%', duration: 0.3, ease: 'power2.out' })
            }
        }

        lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isMobileOpen])

    return (
        <div
            ref={headerRef}
            className={styles['c_header']}
            data-background={bgColor}
            data-is-open={isMobileOpen || isOpen}>
            <Banner
                title='New Lenses Multi-Kafka Developer Experience'
                linkUrl='/apache-kafka-docker'
                linkLabel='Download'
            />
            {isOpen && <div className={styles.overlay} />}
            <Header className={styles.header}>
                <Header.Slot name='logo'>
                    <a className={styles.logo} href='/'>
                        {logo}
                    </a>
                </Header.Slot>
                <Header.Slot name='navigation'>
                    <NavigationComponent nav={nav} setIsOpen={setIsOpen} />
                </Header.Slot>
                <Header.Slot name='actions'>
                    <div className={styles.action_wrapper}>
                        <ClickableArea
                            variant='primary'
                            color='orange'
                            size='s'
                            href={primaryCtaLink}>
                            {primaryCtaText}
                        </ClickableArea>
                    </div>
                </Header.Slot>
                <Header.Slot name='actionsMobile'>
                    <div className='actionsMobile'>
                        <MobileNavigation
                            setIsMobileOpen={setIsMobileOpen}
                            nav={nav}
                            primaryCtaLink={primaryCtaLink}
                            primaryCtaText={primaryCtaText}
                            {...props}
                        />
                    </div>
                </Header.Slot>
            </Header>
        </div>
    )
}

export default HeaderComponent
